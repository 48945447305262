<template>
  <div>
    <b-modal
      id="view-orderItem-details"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('View details')"
      hide-footer
      size="xl"
      @close="onCancel"
    >
      <b-tabs
        v-model="tabIndex"
        card
      >
        <b-tab
          :title="$t('MaterialVariants')"
          active
        >
          <template #title>
            <strong style="font-weight: 500; color: black">{{ $t("MaterialVariants") }}</strong>
          </template>
          <b-card-text><b-form>
            <div
              v-for="material in getMaterialVariantsOfOrder"
              :key="material.materialVariantId"
              :class="{
                failed2: material.isStockAvailable == false,
              }"
              class="material-info"
              style="
                            display: flex;
                            flex-direction: column;
                            margin-bottom: 10px;
                            border: 1px solid lightgray;
                            box-shadow: none;
                            padding: 12px;
                            margin-top: 1%;
                            color: #212529;
                            border-radius: 6px;
                            width: 100%;
                            margin-right: 10px;
                            "
            >
              <!-- <b-button> -->
              <!-- Hover Me -->
              <div style="margin-top:10px;display: flex;justify-content: space-between;">
                <p style="color: #344054; font-weight: 600;">
                  {{ $t('Name') }}:</p> <p>{{ material.name }}</p>

              </div>
              <div class="hr" />
              <div style="margin-top:10px;display: flex;justify-content: space-between;">
                <p style="color: #344054; font-weight: 600;">
                  {{ $t('MeasurementType') }}:
                </p> <p>{{ material.measurementType }}</p>
              </div>
              <div class="hr" />
              <div style="margin-top:10px;display: flex; justify-content: space-between;">
                <p style="color: #344054; font-weight: 600;">
                  {{
                    material.length == 0
                      ? `${$t('Quantity')}:`
                      : `${$t('Length')}:`
                  }}
                </p>
                <p>
                  {{
                    material.length == 0
                      ? (material.quantity === 1 ? material.quantity + ` ${$t('Part')}` : material.quantity > 0 ? material.quantity + ` ${$t('Parts')}` : "")
                      : (material.length === 1 ? material.length + ` ${$t('Meter')}` : material.length > 0 ? material.length + ` ${$t('Meters')}` : "")
                  }}
                </p>
              </div>
              <!-- </b-button> -->
            </div>

          </b-form>
          </b-card-text>
        </b-tab>
      </b-tabs>
      <div class="buttonsEverywhere">
        <b-button
          v-if="tabIndex == 3"
          type="button"
          variant="none"
          style="margin-left: 0px; width: 20%"
          class="buttonSubmit"
          :hidden="$route.path.includes('orders-overview')"
          @click="onSubmit"
        >
          {{ $t("Submit") }}
        </b-button>
        <b-button
          v-if="tabIndex == 2"
          hidden
          style="margin-left: 0px;"
          :disabled="showSubmit == false"
          class="buttonSubmit"
          @click="onSubmitPrice"
        >
          {{ $t("Submit") }}
        </b-button>

      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';


export default {
  components: {
  },
  // eslint-disable-next-line vue/prop-name-casing, vue/require-prop-types
  props: ['order-item-id', 'active-order-status'],
  data() {
    return {
      tabIndex: 1,
    };
  },
  validations: {
  },
  computed: {
    ...mapGetters([
      'getProbeDates',
      'getimagesOrder',
      'getPrices',
      'getTwentyPrinciples',
      'getMaterialVariantsOfOrder',
      'getDescriptionOfOrder',
      'getOrderItemSize',
      'getLoggedInUser',
      'getOrderItemLocatioon',
    ]),
  },
  watch: {
  },
  methods: {
    ...mapActions([]),
  },
};
</script>

<style lang="scss" scoped>
form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  align-items: flex-end;
}
.form2 {
  display: grid;
  grid-template-columns: 3fr 3fr 3fr;
  gap: 10px;
}
.hr{
  background-color: grey;
  min-width: 201px;
  height: 1px;
  border: 0.5px solid #A5A5A5;
  opacity: 0.34;
}
</style>
